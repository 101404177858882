import {
    EffectComposer,
    Bloom,
    HueSaturation,
    BrightnessContrast,
    TiltShift2,TiltShift,
    WaterEffect,
    ToneMapping,  DepthOfField,
    Pixelation,
    Noise,
    Vignette,SSAO
  } from "@react-three/postprocessing";
  import { BlendFunction } from 'postprocessing'
  import VideoBackground from "../components/VideoBackground.jsx";
  import { Preload, AdaptiveDpr, Bvh, Billboard, Environment, AdaptiveEvents } from "@react-three/drei";
export default function Postprocessing01() {

    return (
      <>


{/* <ambientLight intensity={0} /> */}
<Environment
        files='kiara_1_dawn_1k2.hdr'
        path="../models/"
            background={true}
           backgroundBlurriness={0.2}
           backgroundIntensity={0.15}

            // files='kiara_1_dawn_1k.hdr'
            // path="../models/"
          /> 
       <Billboard>
            <VideoBackground
              distance={100}
              intensity={5}
              angle={0.3}
              penumbra={1}
              position={[0, 0, 20]}
            />
          </Billboard>   


 <EffectComposer depthBuffer enableNormalPass> 



 <BrightnessContrast brightness={0.08} contrast={0} />   
     <Bloom mipmapBlur luminanceThreshold={0.8} intensity={2} />    

<ToneMapping
    blendFunction={BlendFunction.NORMAL} // blend mode
    adaptive={true} // toggle adaptive luminance map usage
    resolution={256} // texture resolution of the luminance map
    middleGrey={0.5} // middle grey factor
    maxLuminance={10.0} // maximum luminance
    averageLuminance={1.0} // average luminance
    adaptationRate={1.0} // luminance adaptation rate
  />
         <Noise opacity={0.05} />  
         <Vignette eskil={false} offset={0.1} darkness={1.1} /> 
         </EffectComposer> 
        </>
      );
    
}